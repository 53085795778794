<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V11.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.2091 14.7703L15.6691 18.3103C15.5291 18.4503 15.3991 18.7103 15.3691 18.9003L15.1791 20.2503C15.1091 20.7403 15.4491 21.0803 15.9391 21.0103L17.2891 20.8203C17.4791 20.7903 17.7491 20.6603 17.8791 20.5203L21.4191 16.9803C22.0291 16.3703 22.3191 15.6603 21.4191 14.7603C20.5291 13.8703 19.8191 14.1603 19.2091 14.7703Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.6992 15.2803C18.9992 16.3603 19.8392 17.2003 20.9192 17.5003"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>
