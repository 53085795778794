import http from "@/services/http";
import { QuestionLogType } from "@/core/models/question-log.type";
import { QuestionMessageType } from "@/core/models/question-message.type";

const resource = `${process.env.VUE_APP_API_URL}assessments/`;

const fetchAssessmentLogs = (query: {
  assessment_id: number;
  question_id: number;
}): Promise<QuestionLogType[]> =>
  http.get(`${resource}question_logs/`, { params: query });

const fetchAssessmentMessages = (query: {
  assessment_id: number;
  question_id: number;
}): Promise<QuestionMessageType[]> =>
  http.get(`${resource}question_messages/`, { params: query });

const addAssessmentMessage = (
  data: {
    text: string;
    attachments: any[];
  },
  query: { assessment_id: number; question_id: number }
): Promise<QuestionMessageType> =>
  http.post(`${resource}question_messages/`, data, { params: query });

export default {
  fetchAssessmentLogs,
  fetchAssessmentMessages,
  addAssessmentMessage,
};
