import { CriticalityEnum } from "@/core/enums/questions";
import { QuestionShortType, QuestionType } from "@/core/types/questions";
import { CategoryType } from "@/core/category-type";
import { QuestionLogType } from "@/core/models/question-log.type";

export const CriticalityList = [
  CriticalityEnum.LOW,
  CriticalityEnum.MEDIUM,
  CriticalityEnum.HIGH,
];

export type QuestionsHashMapType = {
  [key: string]: Array<QuestionShortType>;
};

export class QuestionsHashMap {
  public hashMap: QuestionsHashMapType;
  public invalidQuestions: Array<number> = [];

  constructor(private _questions: Array<QuestionShortType | QuestionType>) {
    this._questions = _questions;
    this.hashMap = this.transformToHashMap(_questions);
  }

  get quantity(): number {
    return this._questions.length;
  }

  get list(): Array<QuestionShortType | QuestionType> {
    return this._questions;
  }

  get categories(): Array<CategoryType> {
    const categories: Array<CategoryType> = [];
    this._questions.forEach((question) => {
      if (categories.map((item) => item.id).includes(question.category.id))
        return;
      categories.push(question.category);
    });

    return categories;
  }

  private transformToHashMap(
    questions: Array<QuestionShortType | QuestionType>
  ) {
    const hashMap = {} as QuestionsHashMapType;
    questions.forEach((question) => {
      question.category.collapsed = false;
      if (question.category.name in hashMap) {
        hashMap[question.category.name].push(question);
      } else {
        hashMap[question.category.name] = [question];
      }
    });
    return hashMap;
  }

  public checkIfQuestionsAreValid(): boolean {
    let isValid = true;
    this.invalidQuestions = [];
    Object.keys(this.hashMap).forEach((categoryKey) => {
      const questions = this.hashMap[categoryKey];
      questions.forEach((question) => {
        if (!(question as QuestionType).short_answer) {
          this.invalidQuestions.push(question.id);
          isValid = false;
        }
      });
    });
    return isValid;
  }

  public categoryHasInvalidQuestions(categoryName: string): boolean {
    return this.hashMap[categoryName].some((question) =>
      this.isQuestionInvalid(question.id)
    );
  }

  public isQuestionInvalid(questionId: number): boolean {
    return this.invalidQuestions.includes(questionId);
  }

  public markQuestionAsValid(id: number): void {
    this.invalidQuestions = this.invalidQuestions.filter(
      (questionId) => questionId !== id
    );
  }

  public setQuestionLastLog(id: number, lastLog: QuestionLogType): void {
    const question = this._questions.find((question) => question.id === id);
    if (question) {
      (question as QuestionType).last_log = lastLog;
    }
  }
}
