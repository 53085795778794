<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.88998 1.33337H4.11065C2.09665 1.33337 0.833984 2.75937 0.833984 4.77737V10.2227C0.833984 12.2407 2.09065 13.6667 4.11065 13.6667H9.88932C11.91 13.6667 13.1673 12.2407 13.1673 10.2227V4.77737C13.1673 2.75937 11.91 1.33337 9.88998 1.33337Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.99642 10.1667V7.50006"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.99406 4.9695H7.00073"
      stroke="currentColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UiIconInfo",
};
</script>
