import { FiltersModel } from "@/core/models/filters";
import { CriticalityType } from "@/core/types/questions";

export enum QuestionsFilters {
  CRITICALITY = "criticality",
  CATEGORY = "category",
  EVALUATION = "evaluation",
  SUPPLIER = "supplier",
  EVIDENCE = "need_evidence",
}

export class QuestionsFiltersModel extends FiltersModel {
  public [QuestionsFilters.CRITICALITY]: CriticalityType;
  public [QuestionsFilters.CATEGORY]: string;
  public [QuestionsFilters.SUPPLIER]: string | number;

  constructor(query: any) {
    super();
    this[QuestionsFilters.CRITICALITY] =
      query[QuestionsFilters.CRITICALITY] || null;
    this[QuestionsFilters.CATEGORY] = query[QuestionsFilters.CATEGORY] || null;
    this[QuestionsFilters.SUPPLIER] = query[QuestionsFilters.SUPPLIER] || null;
    this.initAttrsFromQuery(query);
  }
}
