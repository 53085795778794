import { COLORS } from "@/core/constants/colors";
import { QuestionStatusEnum } from "@/core/enums/questions";

export const QUESTION_STATUS_COLORS = {
  [QuestionStatusEnum.COMPLIANT]: COLORS.BLUE,
  [QuestionStatusEnum.NOT_COMPLIANT]: COLORS.RED,
  [QuestionStatusEnum.PARTIALLY_COMPLIANT]: COLORS.YELLOW,
  [QuestionStatusEnum.NOT_EVALUATED]: COLORS.GRAY,
};

export const QUESTION_STATUS_LIST: Array<QuestionStatusEnum> = [
  QuestionStatusEnum.COMPLIANT,
  QuestionStatusEnum.PARTIALLY_COMPLIANT,
  QuestionStatusEnum.NOT_COMPLIANT,
  QuestionStatusEnum.NOT_EVALUATED,
];
