export enum CriticalityEnum {
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  LOW = "LOW",
}

export enum QuestionStatusEnum {
  NOT_EVALUATED = "NOT_EVALUATED",
  NOT_COMPLIANT = "NOT_COMPLIANT",
  PARTIALLY_COMPLIANT = "PARTIALLY_COMPLIANT",
  COMPLIANT = "COMPLIANT",
}

export enum ShortAnswerEnum {
  YES = "YES",
  NO = "NO",
  NOT_RELEVANT = "NOT_RELEVANT",
}
